import { formatDate } from "../utils";
import {
  calculaSubtotalParaChave,
  calculoDesconto,
  totalItensLista,
  totalPagamentos,
  totalVenda,
} from "./pdv.js";
import {
  NfSaidaPagamento,
  NfSaidaEnderecoCliente,
  nfSaidaTotalTributos,
  OperacaoFiscal,
  NfSaidaItem,
} from "./index";

export class NfSaida {
  status = null;
  pedidoIds = null;
  orcamentoIds = null;
  serieDocumento = null;
  dataEmissao = null;
  operacaoFiscalId = null;
  grupoTributarios = null;
  modeloDocumento = null;
  notasCredito = null;
  operacaoFiscal = null;
  chaveAcesso = null;
  quantidadeParcelas = null;
  chaveAcessoReferencia = null;
  clienteId = null;
  clienteEmail = null;
  clienteNome = null;
  clienteCnpjCpf = null;
  vendedorId = null;
  prestadorServicoId = null;
  modalidadeFrete = null;
  freteCompoeNota = null;
  desconto = null;
  descontoPorcentagem = null;
  acrescimo = null;
  despesas = null;
  totalVenda = 0;
  totalMercadoria = 0;
  totalPagamentos = 0;
  informacoesAdicionais = null;
  informacoesFisco = null;
  nfSaidaEnderecoCliente = null;
  documentoDevolucaoCliente = null;
  documentoDevolucaoFornecedor = null;
  frete = null;
  transportadoraId = null;
  transportadoraNome = null;
  transportadoraCnpjCpf = null;
  transportadoraIe = null;
  transportadoraEnderecoRua = null;
  transportadoraEnderecoMunicipio = null;
  transportadoraEnderecoUf = null;
  qtdVolumes = null;
  nfSaidaItens = null;
  nfSaidaPagamentos = null;
  devolucoesClienteIds = null;
  devolucoesFornecedorIds = null;
  nfSaidaImportadaId = null;
  orcamentoImport = null;
  pedidoImport = null;
  importacaoDocumento = null;
  vendedorObrigatorio = null;
  enderecoEntregaId = null;
  vendedorBancoCaixaId = null;
  meioPagamentoId = null;
  condicaoPagamentoId = null;
  troco = null;

  constructor(nfSaida) {
    this.status = nfSaida.status;
    this.orcamentoImport = nfSaida.orcamentoImport;
    this.pedidoImport = nfSaida.pedidoImport;
    this.importacaoDocumento = nfSaida.importacaoDocumento;
    this.pedidoIds = nfSaida.pedidoIds;
    this.orcamentoIds = nfSaida.orcamentoIds;
    this.serieDocumento = nfSaida.serieDocumento;
    this.numeroDocumento = nfSaida.id ? nfSaida.numeroDocumento : undefined;
    this.dataEmissao = nfSaida.dataEmissao || formatDate.toSend(new Date());
    this.operacaoFiscalId = nfSaida.operacaoFiscalId;
    this.grupoTributarios = nfSaida.grupoTributarios;
    this.modeloDocumento = nfSaida.modeloDocumento;
    this.notasCredito = nfSaida.notasCredito;
    this.operacaoFiscal = nfSaida.operacaoFiscal;
    this.chaveAcesso = nfSaida.chaveAcesso;
    this.quantidadeParcelas = nfSaida.quantidadeParcelas;
    this.chaveAcessoReferencia = nfSaida.chaveAcessoReferencia;
    this.clienteId = nfSaida.clienteId;
    this.clienteEmail = nfSaida.clienteEmail;
    this.clienteNome = nfSaida.clienteNome;
    this.clienteCnpjCpf = nfSaida.clienteCnpjCpf;
    this.vendedorId = nfSaida.vendedorId;
    this.vendedorBancoCaixaId = nfSaida.vendedorBancoCaixaId;
    this.prestadorServicoId = nfSaida.prestadorServicoId;
    this.modalidadeFrete = nfSaida.modalidadeFrete || "mfSemFrete";
    this.transportadoraId = nfSaida.transportadoraId;
    this.transportadoraNome = nfSaida.transportadoraNome;
    this.transportadoraCnpjCpf = nfSaida.transportadoraCnpjCpf;
    this.transportadoraIe = nfSaida.transportadoraIe;
    this.transportadoraEnderecoRua = nfSaida.transportadoraEnderecoRua;
    this.transportadoraEnderecoMunicipio =
      nfSaida.transportadoraEnderecoMunicipio;
    this.transportadoraEnderecoUf = nfSaida.transportadoraEnderecoUf;
    this.qtdVolumes = nfSaida.qtdVolumes;
    this.freteCompoeNota = nfSaida.freteCompoeNota || false;
    this.desconto = nfSaida.desconto;
    this.descontoPorcentagem = nfSaida.descontoPorcentagem;
    this.acrescimo = nfSaida.acrescimo;
    this.despesas = nfSaida.despesas;
    this.totalMercadoria = nfSaida.totalMercadoria;
    this.totalPagamentos =
      nfSaida.totalPagamentos || totalPagamentos(this.nfSaidaPagamentos);
    this.totalVenda = nfSaida.totalVenda;
    this.informacoesAdicionais = nfSaida.informacoesAdicionais;
    this.informacoesFisco = nfSaida.informacoesFisco;
    this.nfSaidaEnderecoCliente = nfSaida.nfSaidaEnderecoCliente
      ? new NfSaidaEnderecoCliente(nfSaida.nfSaidaEnderecoCliente)
      : null;
    this.documentoDevolucaoCliente = nfSaida.documentoDevolucaoCliente;
    this.documentoDevolucaoFornecedor = nfSaida.documentoDevolucaoFornecedor;
    this.frete = nfSaida.frete;
    this.nfSaidaItens = nfSaida.nfSaidaItens;
    this.nfSaidaPagamentos = nfSaida.nfSaidaPagamentos?.length
      ? nfSaida.nfSaidaPagamentos.map(
          (pagamento) => new NfSaidaPagamento(pagamento)
        )
      : [];
    this.devolucoesClienteIds = nfSaida?.devolucoesClienteIds || null;
    this.devolucoesFornecedorIds = nfSaida?.devolucoesFornecedorIds || null;
    this.nfSaidaImportadaId = nfSaida.nfSaidaImportadaId;
    this.vendedorObrigatorio = nfSaida.vendedorObrigatorio;
    this.enderecoEntregaId = nfSaida.enderecoEntregaId;
    this.meioPagamentoId = nfSaida.meioPagamentoId;
    this.condicaoPagamentoId = nfSaida.condicaoPagamentoId;
    this.dataEmissao = nfSaida?.dataEmissao || formatDate.toSend(new Date());
    this.modalidadeFrete = nfSaida?.modalidadeFrete || "mfSemFrete";
    this.freteCompoeNota = nfSaida?.freteCompoeNota || false;
  }

  setChangeItens(id, itens, nfSaida = this) {
    this.desconto = calculaSubtotalParaChave(itens, "", "desconto");
    this.acrescimo = calculaSubtotalParaChave(itens, "", "acrescimo");
    this.despesas = calculaSubtotalParaChave(itens, "", "despesas");
    this.nfSaidaItens = itens?.length
      ? itens.map(
          (item, index) => new NfSaidaItem(item, index, this.operacaoFiscal)
        )
      : [];
    this.baseIcmsTotal = nfSaidaTotalTributos.totalBaseIcms(id, itens, nfSaida);
    this.valorIcmsTotal = nfSaidaTotalTributos.totalValorIcms(
      id,
      itens,
      nfSaida
    );
    this.baseIcmsSt = nfSaidaTotalTributos.totalBaseIcmsSt(id, itens, nfSaida);
    this.valorIcmsSt = nfSaidaTotalTributos.totalValorIcmsSt(
      id,
      itens,
      nfSaida
    );
    this.valorIpiTotal = nfSaidaTotalTributos.totalValorIpi(id, itens, nfSaida);
    this.totalMercadoria = totalItensLista(itens);
    this.totalVenda = totalVenda(nfSaida, itens);
    this.descontoPorcentagem = calculoDesconto(itens, nfSaida, false);
    this.grupoTributarios = [
      ...new Set(this.nfSaidaItens.map((item) => item.grupoTributacaoId)),
    ];
  }

  setChangePagamentos(pagamentos) {
    this.nfSaidaPagamentos = pagamentos?.length
      ? pagamentos.map((pagamento) => new NfSaidaPagamento(pagamento))
      : [];
    this.totalPagamentos = totalPagamentos(pagamentos);
  }

  setIdentificarCliente(value) {
    this.clienteId = value?.id;
    this.clienteEmail = value?.email;
    this.clienteCnpjCpf = value?.cnpjCpf;
    this.clienteNome = value?.nomeRazaoSocial;
  }

  setVendedorObrigatorioPermissao(permissao) {
    this.vendedorObrigatorio = permissao;
  }

  setEnderecoCliente(enderecoAlterado, enderecoCliente) {
    if (this.clienteId) {
      const endereco = enderecoAlterado || enderecoCliente;
      if (endereco) {
        this.nfSaidaEnderecoCliente = {
          ...endereco,
          pessoaId: this.clienteId,
        };
        return;
      }
    }
    this.nfSaidaEnderecoCliente = {};
  }

  setHandleChangeInput(name, value) {
    this[name] = value;
  }

  setHandleChangeDate(name, date) {
    this[name] = date !== "Invalid Date" ? formatDate.toSend(date) : undefined;
  }

  setHandleChangeAutoComplete(name, value) {
    this[name] = value ? value.id : null;
    if (name === "clienteId") {
      this.setIdentificarCliente(value);
    }
    if (name === "transportadoraId") {
      this.transportadoraNome = value?.nomeRazaoSocial;
      this.transportadoraCnpjCpf = value?.cnpjCpf;
      this.transportadoraIe = value?.inscricaoEstadual;
      this.transportadoraEnderecoRua = value?.enderecos[0]?.logradouro;
      this.transportadoraEnderecoMunicipio = value?.enderecos[0]?.cidade?.nome;
      this.transportadoraEnderecoUf = value?.enderecos[0]?.cidade?.estado.sigla;
    }
  }

  setOperacaoFiscal(operacaoFiscal) {
    this.operacaoFiscal = new OperacaoFiscal(operacaoFiscal);
    this.operacaoFiscalId = operacaoFiscal.id;
    this.serieDocumento = operacaoFiscal?.serieDocumento;
    this.modeloDocumento = operacaoFiscal?.modeloDocumento;
  }

  setColaboradorVendedorUsuario(
    vendedorId,
    vendedorBancoCaixaId,
    permissaoUtilizarCaixa
  ) {
    this.vendedorId = vendedorId;
    if (permissaoUtilizarCaixa) {
      this.vendedorBancoCaixaId = vendedorBancoCaixaId;
    }
  }

  setNotasCredito(notasCredito) {
    this.notasCredito = notasCredito;
  }

  setVendedorBancoCaixaId(bancoCaixaId) {
    this.vendedorBancoCaixaId = bancoCaixaId;
  }

  setDadosImportacao(importacao) {
    this.pedidoImport = true;
    this.informacoesAdicionais = importacao.informacoesAdicionais;
    this.pedidoIds = importacao.pedidoIds;
    this.clienteId = importacao?.clienteId;
    this.vendedorId = importacao?.vendedorId;
    this.desconto = importacao.desconto ?? 0;
    this.acrescimo = importacao.acrescimo ?? 0;
    this.despesas = importacao.despesas ?? 0;
    this.prestadorServicoId = importacao.prestadorServicoId;
  }

  setTroco(troco) {
    this.troco = troco;
  }
}
