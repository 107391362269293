export class ImpressaoPrimariaEntity {
  imagemLogo = null;
  aspectRatio = null;
  cnpjCpfEmitente = null;
  nomeRazaoSocialEmitente = null;
  enderecoEmitente = {};
  ieEmitente = null;
  ambiente = null;
  itens = [];
  pagamentos = [];
  totalVenda = 0;
  totalMercadoria = 0;
  desconto = 0;
  urlChave = "";
  nfeChave = "";
  qrCodeImage = null;
  numeroNota = null;
  serie = null;
  dataHora = null;
  protocolo = null;
  status = null;
  informacoes = null;

  constructor(data) {
    this.imagemLogo = data.imagemLogo;
    this.aspectRatio = data.aspectRatio;
    this.cnpjCpfEmitente = data.cnpjCpfEmitente;
    this.nomeRazaoSocialEmitente = data.nomeRazaoSocialEmitente;
    this.enderecoEmitente = data.enderecoEmitente;
    this.ieEmitente = data.ieEmitente;
    this.ambiente = data.ambiente;
    this.itens = data.itens?.length
      ? data.itens.map((item) => new Item(item))
      : [];
    this.pagamentos = data.pagamentos?.length
      ? data.pagamentos.map((pagamento) => new Pagamento(pagamento))
      : [];
    this.totalVenda = data.totalVenda;
    this.totalMercadoria = data.totalMercadoria;
    this.desconto = data.desconto;
    this.urlChave = data.urlChave;
    this.nfeChave = data.nfeChave;
    this.qrCodeImage = data.qrCodeImage;
    this.numeroNota = data.numeroNota;
    this.serie = data.serie;
    this.dataHora = data.dataHora;
    this.protocolo = data.protocolo;
    this.status = data.status;
    this.informacoes = data.informacoes;
  }
}

class Item {
  prod = {
    cProd: null,
    xProd: null,
    qCom: null,
    uCom: null,
    vUnCom: null,
  };

  constructor(item) {
    this.prod.cProd = item.prod.cProd;
    this.prod.xProd = item.prod.xProd;
    this.prod.qCom = item.prod.qCom;
    this.prod.uCom = item.prod.uCom;
    this.prod.vUnCom = item.prod.vUnCom;
  }
}

class Pagamento {
  detPag = {
    tPag: null,
    vPag: null,
  };

  constructor(pagamento) {
    this.tPag = pagamento.tPag;
    this.vPag = pagamento.vPag;
  }
}
