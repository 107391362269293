import formatPrecisaoFloat from "./format-precisao-float";

export function formatProdutosTotalizadores(
  name,
  nfSaidaItens,
  valueInputReal,
  totalNota
) {
  const valueInput = parseFloat(valueInputReal || 0);
  let listaAtualizada = nfSaidaItens.map((item) => {
    const valorProporcional =
      formatPrecisaoFloat((subtotalItem(item) * 100) / totalNota) / 100;
    return {
      ...item,
      [name]: valueInput > 0 ? formatPrecisaoFloat(valorProporcional * valueInput) : 0,
    };
  });
  ajustarDiferenca(name, valueInput, listaAtualizada);
  return listaAtualizada;
}

export function subtotalItem(item) {
  return parseFloat(
    item.subtotal || item.quantidade * (item.valorUnitario || item.valor)
  );
}

export function getIndexItemMaisCaro(lista) {
  return lista.reduce(
    (maxIndex, currentItem, currentIndex, array) =>
      subtotalItem(currentItem) > subtotalItem(array[maxIndex])
        ? currentIndex
        : maxIndex,
    0
  );
}

export function calculaSubtotalParaChave(lista, tipoOperacao, chave) {
  if (lista?.length > 0) {
    const valorCalculado = lista.reduce((acumulador, objetoAtual) => {
      return acumulador + parseFloat(objetoAtual[chave] ?? 0);
    }, 0);
    return tipoOperacao !== chave ? valorCalculado : 0;
  }
}

export function ajustarDiferenca(key, valorTotal, listaAtualizada) {
  const subtotal = calculaSubtotalParaChave(listaAtualizada, "", key);
  if (valorTotal !== subtotal) {
    const indiceItemMaisCaro = getIndexItemMaisCaro(listaAtualizada);
    const diferenca = valorTotal - subtotal;
    listaAtualizada[indiceItemMaisCaro][key] += diferenca;
  }
}

export function formatProdutosTotalizadoresDto(
  nfSaidaItens,
  totalNota,
  nfSaida
) {
  const { acrescimo = 0, despesas = 0, desconto = 0 } = nfSaida;
  let listaAtualizada = nfSaidaItens.map((item) => {
    const valorProporcional =
      formatPrecisaoFloat((subtotalItem(item) * 100) / totalNota) / 100;
    const novosValores = {
      acrescimo: formatPrecisaoFloat(valorProporcional * acrescimo),
      despesas: formatPrecisaoFloat(valorProporcional * despesas),
      desconto: formatPrecisaoFloat(valorProporcional * desconto),
    };
    return {
      ...item,
      ...novosValores,
    };
  });
  ajustarDiferenca("acrescimo", acrescimo, listaAtualizada);
  ajustarDiferenca("despesas", despesas, listaAtualizada);
  ajustarDiferenca("desconto", desconto, listaAtualizada);

  return listaAtualizada;
}
