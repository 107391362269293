import * as Yup from "yup";

const nfSaidaItensValidator = (operacaoFiscal) =>
  Yup.array()
    .of(
      Yup.object().shape({
        produtoId: Yup.number().required(),
        unidade: Yup.string().optional().nullable(),
        quantidade: Yup.number().required(),
        valor: Yup.number().required(),
        subtotal: Yup.number().required(),
        modeloTributacaoId: Yup.number().nullable(),
        grupoTributacaoId: Yup.number().required(),
        descricao: Yup.string().optional().nullable(),
        codigo: Yup.string().optional().nullable(),
        codigoBarra: Yup.string().optional().nullable(),
        ncm: Yup.string()
          .min(8, "NCM deve ter pelo menos 8 caracteres")
          .required("NCM"),
        cfop: Yup.number().optional().nullable(),
        impostoIcmsStReducao: Yup.number().optional().nullable(),
        impostoIcmsStBase: Yup.number().optional().nullable(),
        impostoIcmsStAliquota: Yup.number().optional().nullable(),
        impostoIcmsSt: Yup.number().optional().nullable(),
        unidadeCom: Yup.string().optional().nullable(),
        unidadeTrib: Yup.string().optional().nullable(),
        quantidadeCom: Yup.number().optional().nullable(),
        quantidadeTrib: Yup.number().optional().nullable(),
        valorUnitarioComercial: Yup.number().optional().nullable(),
        valorUnitarioTributavel: Yup.number().optional().nullable(),
        desconto: Yup.number().optional().nullable(),
        acrescimo: Yup.number().optional().nullable(),
        tributosIcmsOrigem:
          operacaoFiscal?.modeloDocumento !== "01"
            ? Yup.number().required("Tributos ICMS Origem")
            : Yup.number().optional().nullable(),
        tributosIcmsCst: Yup.string().optional().nullable(),
        tributosIcmsCsosn: Yup.string().optional().nullable(),
        tributosIcmsBaseCalculoModalidade: Yup.number().optional().nullable(),
        tributosIcmsBaseCalculoModalidadeST: Yup.number().optional().nullable(),
        tributosIcmsBaseCalculoPercentualReducao: Yup.number()
          .optional()
          .nullable(),
        tributosIcmsBaseCalculoValor: Yup.number().optional().nullable(),
        tributosIcmsAliquota: Yup.number().optional().nullable(),
        tributosIcmsValor: Yup.number().optional().nullable(),
        tributosPisCst: Yup.string().optional().nullable(),
        tributosPisBaseCalculoValor: Yup.number().optional().nullable(),
        tributosPisBaseCalculoQuantidade: Yup.number().optional().nullable(),
        tributosPisAliquota: Yup.number().optional().nullable(),
        tributosPisValor: Yup.number().optional().nullable(),
        tributosCofinsCst: Yup.string().optional().nullable(),
        tributosCofinsBaseCalculoValor: Yup.number().optional().nullable(),
        tributosCofinsAliquota: Yup.number().optional().nullable(),
        tributosCofinsValor: Yup.number().optional().nullable(),
        informacaoAdicional: Yup.string().optional().nullable(),
      })
    )
    .min(1, "Adicionar ao menos um item")
    .required();

export default nfSaidaItensValidator;
