import formatPrecisaoFloat from "./format-precisao-float";
import { totalItensLista } from "./total-itens-lista";

export default function calculoDesconto(
  itens,
  nfSaida,
  porCemParaDecimal = true
) {
  const total =
    totalItensLista(itens) +
    parseFloat(nfSaida?.acrescimo ?? 0) +
    parseFloat(nfSaida?.despesas ?? 0);

  let resultado;
  if (porCemParaDecimal) {
    const descontoPorcentagem = parseFloat(nfSaida?.descontoPorcentagem ?? 0);
    resultado = (descontoPorcentagem / 100) * total;
    resultado = formatPrecisaoFloat(resultado);
  } else {
    const desconto = parseFloat(nfSaida?.desconto ?? 0);
    resultado = (desconto / formatPrecisaoFloat(total)) * 100;
    resultado = formatPrecisaoFloat(resultado);
  }
  return resultado || 0;
}
